import { ReactComponent as Logo } from "../../assets/vectors/logo.svg";

const Footer = () => {
  return (
    <footer className="pt-10 flex-1">
      <div className="flex items-center flex-col mx-7">
        <Logo className="h-40" />
        <p className="py-5 dark:text-gray-300">FTRPRF ©2022</p>
      </div>
    </footer>
  );
};

export default Footer;
