import { useEffect, useState } from "react";
import {
  EmojiHappyIcon,
  EmojiSadIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

const StatusElement = ({ healthUrl, name, className, playgroundUrl }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();

    setTimeout(() => {
      abortController.abort();
      setStatus((prev) => (prev === null ? false : prev));
    }, 30000);

    const fetchHealth = async () => {
      setStatus(null);
      try {
        const res = await fetch(healthUrl, { signal: abortController.signal });
        setStatus(res.ok);
      } catch {
        setStatus(false);
      }
    };

    fetchHealth();
  }, [healthUrl]);

  const getIcon = () => {
    const sz = 28;
    switch (status) {
      case true:
        return (
          <EmojiHappyIcon
            className="text-green-500 dark:text-green-800"
            width={sz}
            height={sz}
          />
        );

      case false:
        return (
          <EmojiSadIcon
            className="text-red-500 dark:text-red-800"
            width={sz}
            height={sz}
          />
        );
      default:
        return (
          <RefreshIcon
            className="text-orange-500 dark:text-orange-700"
            width={sz}
            height={sz}
          />
        );
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case true:
        return "border-green-500 dark:border-green-800";
      case false:
        return "border-red-500 dark:border-red-800";
      default:
        return "border-orange-500 dark:border-orange-700";
    }
  };

  return (
    <div
      className={`flex p-3 my-5 rounded-md border-2 ${className} ${getBorderColor()}`}
    >
      <div className="container">
        <div className="flex flex-row">
          <p className="font-semibold">{name}</p>
          <div className="ml-auto">{getIcon()}</div>
        </div>
        <div className="flex gap-2 mt-4">
          {healthUrl && (
            <a
              href={healthUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center rounded-md bg-gray-200 dark:bg-gray-700 px-3 py-1 text-sm font-medium text-white-100 hover:bg-gray-400 dark:hover:bg-gray-500 hover:underline"
            >
              health
            </a>
          )}
          {playgroundUrl && (
            <a
              href={playgroundUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center rounded-md bg-gray-200 dark:bg-gray-700 px-3 py-1 text-sm font-medium text-white-100 hover:bg-gray-400 dark:hover:bg-gray-500 hover:underline"
            >
              playground
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusElement;
