import { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Toggle from "../../components/Toggle/Toggle";
import StatusElement from "./partials/StatusElement";

const URLS_TEST = [
  {
    healthUrl: "https://test-education-backend.ftrprf.be/api/health",
    name: "EDU",
  },
  {
    healthUrl: "https://test-studio-backend.ftrprf.be/rest/health",
    playgroundUrl: "https://test-studio-backend.ftrprf.be/playground",
    name: "STUDIO",
  },
  {
    healthUrl: "https://test-portfolio.ftrprf.be/rest/health",
    playgroundUrl: "https://test-portfolio.ftrprf.be/playground",
    name: "PORTFOLIO",
  },
  {
    healthUrl: "https://test-store-backend.codecosmos.com/actuator/health",
    playgroundUrl: "https://test-store-backend.codecosmos.com/playground",
    name: "CODECOSMOS",
  },
  {
    healthUrl: "https://test-planner.ftrprf.be/actuator/health",
    playgroundUrl: "https://test-planner.ftrprf.be/playground",
    name: "PLANNER",
  },
  {
    healthUrl: "https://test-slideviewer-backend.ftrprf.be/actuator/health",
    playgroundUrl: "https://test-slideviewer-backend.ftrprf.be/playground",
    name: "SLIDEVIEWER",
  },
  {
    healthUrl: "https://user-service-tst.azurewebsites.net/rest/health",
    name: "USER",
  },
];

const URLS_PROD = [
  {
    healthUrl: "https://education-backend.ftrprf.be/api/health",
    name: "EDU",
  },
  {
    healthUrl: "https://studio-backend.ftrprf.be/rest/health",
    name: "STUDIO",
  },
  {
    healthUrl: "https://portfolio.ftrprf.be/rest/health",
    name: "PORTFOLIO",
  },
  {
    healthUrl: "https://store-backend.codecosmos.com/actuator/health",
    name: "CODECOSMOS",
  },
  {
    healthUrl: "https://planner-backend.ftrprf.be/actuator/health",
    name: "PLANNER",
  },
  {
    healthUrl: "https://slideviewer-backend.ftrprf.be/actuator/health",
    name: "SLIDEVIEWER",
  },
  {
    healthUrl: "https://user-service-prd.azurewebsites.net/rest/health",
    name: "USER",
  },
];

const Status = () => {
  const [showProd, setShowProd] = useState(false);
  const [showUrls, setShowUrls] = useState(URLS_TEST);

  useEffect(() => {
    if (showProd) setShowUrls(URLS_PROD);
    if (!showProd) setShowUrls(URLS_TEST);
  }, [showProd, showUrls]);

  return (
    <div className="flex justify-center">
      <div className="p-5 max-w-xl">
        <div className="flex md:flex-row flex-col gap-4 items-center">
          <h1 className="text-2xl font-bold mr-auto">
            Service status - {showProd ? "Prod" : "Test"}
          </h1>
          <Toggle enabled={showProd} setEnabled={setShowProd} />
        </div>
        {showUrls
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((endpoint) => {
            if (endpoint.healthUrl) {
              return (
                <StatusElement
                  key={endpoint.name}
                  healthUrl={endpoint.healthUrl}
                  name={endpoint.name}
                  playgroundUrl={endpoint.playgroundUrl || ""}
                />
              );
            } else {
              return null;
            }
          })}
        <Footer />
      </div>
    </div>
  );
};

export default Status;
